







@import 'base/reset';
@import 'base/typography';
@import 'variables';

$legend-icon-size: 4rem;



html, body {
    font-family: 'Fira Sans', sans-serif;
    min-height: 100vh;
}

.container {
    max-width: 100%;//max-width: 82rem;
    margin: 0 auto;
    overflow-x: auto;
    padding: 1rem;
}
.view-technology, .view-calendar {
    display: flex; justify-content: center; flex-direction: column;
    width: min-content; min-width: 100%;
    margin-top: 2rem;
    gap: $gap;
}

.powerplants {
    &-container {
        position: relative; z-index: 1;
        // max-width: 100%;
        max-width: 82rem;
        margin: 0 auto;
        overflow-x: auto;
        padding: 1rem;
    }
    &-wrapper {
        display: flex; justify-content: center;
        width: min-content; min-width: 100%;
        margin-top: 2rem;
        gap: $gap;
    }
    &-grid {
        display: grid;
        grid-auto-flow: column;
        min-width: 250px;
        width: min-content;
        grid-template-rows: 1fr 1fr;
        gap: $gap;
    }
    /**
     * Title
     */
    &-title {
        font-size: 32px;
        background: -webkit-linear-gradient(0deg, #79b829, #006027);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }// Title
    /**
     * Header
     */
     &-header {
        display: flex;
        margin-bottom: 2rem;// Dotted
        &-wrapper {
            display: flex; justify-content: center; align-items: center;
            width: $powerplant-width;
        }
        &-bubble {
            position: relative;
            display: flex; justify-content: center; align-items: center;
            width: $bubble-size; height: $bubble-size;
            border-radius: 50%;
            img {
                flex-shrink: 0;
                width: 115%; height: 115%;
            }
            &::after {// Dotted link to the first powerplant
                position: absolute; top: 100%;
                left: calc(50% - $dot-thickness / 2);
                display: block;
                content: '';
                height: 5rem;
                border-left: $dot-thickness dotted $primary-dot;
            }
        }
    }// Header
    /**
     * Legend
     */
    &-legend {
        display: flex; justify-content: flex-start;
        width: max-content;
        margin-top: 2rem;
        &-item {
            display: flex; flex-direction: column;
            width: 10rem;
            text-align: center;
            font-size: 0.8rem;
            &-icon img {
                width: $legend-icon-size; height: $legend-icon-size;
            }
        }
    }// Legend
    
}




.view-technology {
    &-wrapper {
        display: flex; justify-content: flex-end; flex-direction: column;
        width: min-content;
        gap: 4rem;
    }
}


.view-calendar {
    &-grid {
        display: flex;
        gap: $gap;
    }
    &-year {
        position: relative;
        display: flex; flex-direction: column;
        width: max-content;
        gap: 3rem;
        &-empty {
            position: absolute; top: calc($bubble-size / 2 - $dot-thickness / 2);
            width: $disabled-dot-width;
            border-bottom: $dot-thickness dotted $disabled-dot;
            transform: translateX(-50%);
        }
    }
    .powerplants-header-bubble {
        background: $secondary-gradient-start;
        background: linear-gradient(90deg, $secondary-gradient-start, $secondary-gradient-end);
        font-size: 24px;
        color: $year-text-light;
    }
}

/**
 * Widget powerplant
 */
.powerplant {
    position: relative;
    display: flex; flex-direction: column; align-items: center;
    width: $powerplant-width;
    padding: 1rem 1rem 0.5rem;
    border-radius: 1rem;
    &.isEven {
        &::before {
            position: absolute; bottom: calc(100% + $powerplant-border);
            left: calc(50% - $dot-thickness / 2);
            display: block;
            content: '';
            height: $gap;
            border-left: $dot-thickness dotted $primary-dot;
        }
    }
    &.isOdd.isFollowed {
        &::before {
            position: absolute; top: calc(50%); right: calc(100% + $powerplant-border);
            display: block;
            content: '';
            height: calc(50% + ($gap + $dot-thickness + $powerplant-border) / 2);
            width: calc(($gap + $dot-thickness) / 2);
            border-left: $dot-thickness dotted $primary-dot;
            border-top: $dot-thickness dotted $primary-dot;
        }
    }
    &.isEven.hasFollowing {
        &:after {
            position: absolute; bottom: calc(50%); left: calc(100% + $powerplant-border);
            display: block;
            content: '';
            height: calc(50% + ($gap + $dot-thickness + $powerplant-border) / 2);
            width: calc(($gap + $dot-thickness) / 2);
            border-right: $dot-thickness dotted $primary-dot;
            border-bottom: $dot-thickness dotted $primary-dot;
        }
    }
    &.power-1 {
        background-color: $powerplant-power1-background;
        border: $powerplant-border solid $powerplant-power1-background;
    }
    &.power-2 {
        background-color: $powerplant-power2-background;
        border: $powerplant-border solid $powerplant-power2-background;
    }
    &.power-3 {
        background-color: $powerplant-power3-background;
        border: $powerplant-border solid $powerplant-power3-background;
    }
    &.futureBuild {
        background-color: $powerplant-futurebuild-background;
    }
    &-builddate {
        margin-bottom: 0.75rem;
        background: -webkit-linear-gradient(0deg, $secondary-gradient-start, $secondary-gradient-end);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.4rem;
        font-weight: 800;
    }
    &-name {
        margin-bottom: 0.25rem;
        background: -webkit-linear-gradient(0deg, $primary-gradient-start, $primary-gradient-end);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.4rem;
        font-weight: 800;
        text-transform: uppercase;
    }
    &-description {
        display: flex;
        height: 2.4rem;
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 1rem;
        line-height: 1.2rem;
    }
    &-powers {
        display: flex;
        margin-bottom: 0.25rem;
        gap: 1.5rem;
    }
    &-technologies {
        display: flex; justify-content: center;
        flex-wrap: wrap;
        &-item {
            position: relative;
            width: $powerplant-icon-size; height: $powerplant-icon-size;
            img {
                width: $powerplant-icon-size; height: $powerplant-icon-size;
            }
        }
    }
    &-power {
        position: relative;
        display: flex; flex-direction: column; align-items: center;
        width: 5.4rem;
        margin-top: 1rem;
        padding: 0.2rem 0;
        border-radius: 0.25rem;
        background-color: $powerplant-background-light;
        border: 3px solid $primary-500;
        color: $primary-500;
        font-weight: 600;
        svg {
            position: absolute; top: 0; left: 0;
            width: 3rem;
            transform: translate(-60%, -50%);
        }
        .power-1 {
            color: $powerplant-power1-background;
            fill: currentColor;
        }
        .power-2 {
            color: $powerplant-power2-background;
            fill: currentColor;
        }
        &.power-3 {
            color: $powerplant-power3-background;
            fill: currentColor;
        }
        &.power {
            font-size: 1rem;
        }
        &.unit {
            position: relative;
            font-size: 0.75rem;
        }
        &.type {
            position: relative; top: 0.15rem;
            font-size: 0.6rem;
        }
    }
}

/**
 * Filter
 */
.filter {
    position: fixed; top: 0; right: 0; left: 0; z-index: 100;
    background: rgba(#ffffff, 0.8);
    &-wrapper {
        display: flex; align-items: stretch;
        max-width: 81.25rem; height: 5rem;
        margin: 0 auto;
        padding: 1rem;
        gap: 1rem;
    }
    &-search {
        position: relative;
        display: flex; align-items: stretch;
        flex-grow: 1; //max-width: 50vw;
        &-label {
            position: absolute; top: 0; left: 0;
            display: flex; justify-content: center; align-items: center;
            height: 100%; aspect-ratio: 1 / 1;
            svg {
                width: 1.4rem; height: 1.4rem;
            }
        }
        &-input {
            width: 100%;
            padding: 0 1rem 0 3rem;
            border: 1px solid #cccccc;
        }
        &-clear {
            position: absolute; top: 0; right: 0;
            display: flex; justify-content: center; align-items: center;
            height: 100%; aspect-ratio: 1 / 1;
            svg {
                width: 1.4rem; height: 1.4rem;
            }
        }
    }
    &-button {
        position: relative; z-index: 2;
        display: flex; justify-content: center; align-items: center;
        height: 100%; aspect-ratio: 1 / 1;
        color: #ffffff;
        transition: opacity 300ms ease-in-out ;
        background: linear-gradient(90deg, $primary-gradient-start, $primary-gradient-end);
        &.secondary {
            background: linear-gradient(90deg, $secondary-gradient-start, $secondary-gradient-end);
            &::before {
                background: linear-gradient(90deg, $secondary-gradient-start, $secondary-gradient-end, $secondary-gradient-start);
            }
        }
        &::before {
            position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
            content: '';
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            background: linear-gradient(90deg, $primary-gradient-start, $primary-gradient-end, $primary-gradient-start);
        }
        &:hover::before {
            opacity: 1;
        }
        &.active {
            border: 2px solid $secondary-gradient-end;
        }
        svg {
            position: relative; z-index: 2;
            width: 1.4rem; height: 1.4rem;
            fill: currentColor;
        }
    }
    &-dropdown {
        position: absolute; top: 100%; right: 0; z-index: 100;
        width: 18rem;
        background: #ffffff;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        &-wrapper {
            position: relative;
        }
        &-title {
            padding: 1rem;
            text-align: center;
            font-weight: 600;
            font-size: 1.2rem;
            background: -webkit-linear-gradient(0deg, $secondary-gradient-start, $secondary-gradient-end);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &-list {
            &-item {//:not(:last-child)
                border-bottom: 1px solid #cccccc;
                &-button {
                    display: flex; align-items: center;
                    gap: 1rem;
                    padding: 0.125rem 1rem;
                    width: 100%;
                    color: #111111;
                    &:hover {
                        background-color: lighten($secondary-gradient-start, 0%);
                    }
                    img {
                        width: 2.5rem; height: 2.5rem;
                        flex-shrink: 0;
                    }
                    &-icon {
                        display: flex; justify-content: center; align-items: center;
                        width: 2.1rem; height: 2.1rem;
                        flex-shrink: 0;
                        margin: 0.2rem;
                        border-radius: 50%;
                        background-color: #ffffff;
                        border: $primary-500 solid 2px;
                        color: $primary-500;
                        svg {
                            width: 1.5rem; height: 1.5rem;
                            flex-shrink: 0;
                            fill: currentColor;
                        }
                    }
                }
            }
        }
        &-power {
            &-grid {
                display: flex;
                font-size: 0.8rem;
                text-align: center;
                & > * {
                    padding: 0.75rem 0;
                    background-color: #fff;
                    transition: all 200ms ease-in-out;
                    &.active {
                        color: #ffffff;
                    }
                }
            }
            &-1, &-3 {
                width: 30%;
            }
            &-2 {
                flex-grow: 1;
            }
            &-1.active {
                background-color: darken($primary-gradient-start, 10%);
            }
            &-2.active {
                background-color: darken($secondary-gradient-start, 2%);
            }
            &-3.active {
                background-color: darken($secondary-gradient-end, 5%);
            }
        }
    }
}



