


.header {
    --height: 5rem;
    --text-color: #616366;
    --text-color-hover: #ff7900;
    --text-color-active: #d52b1e;
    
    &Nav {
        display: flex; justify-content: space-between; align-items: center;
        max-width: 81.25rem;
        height: var(--height);
        margin: 0 auto;
        padding: 0 1rem;
        &Logo {
            position: relative;
            width: 102px; height: 50px;
            img {
                width: 102px; height: 50px;
            }
        }
        &Menu {
            display: flex; align-items: center;
            gap: 1rem;
            &Item {
                &Link {
                    display: flex; align-items: center;
                    height: var(--height);
                    padding: 0 1rem;
                    font-weight: 500;
                    color: var(--text-color);
                    transition: color 300ms ease-in-out;
                    &:hover {
                        color: var(--text-color-hover);
                    }
                }
            }
        }
    }
}