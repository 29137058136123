



$gap: 3rem;
// Dots
$dot-thickness: 0.35rem;
$primary-dot: #006027;
$disabled-dot: #cccccc;
$disabled-dot-width: 5rem;
// Bubble
$bubble-size: 6.875rem;
// Widget year
$year-size: 6.875rem;
$year-margin-b: 6.875rem;
$year-background-light: #ffffff;;
$year-text-light: #ffffff;
$year-border: 0.5rem;

// Widget powerplant
$powerplant-width: 18rem;
$powerplant-border: 0.75rem;
$powerplant-icon-size: 5rem;
$powerplant-background-light: #ffffff;
$powerplant-futurebuild-background: #ffffff;
$powerplant-power1-background: #ffffff;
$powerplant-power2-background: #eaf1dc;
$powerplant-power3-background: #d5e2b5;



// Colors
$primary-500: #86b441;
$primary-gradient-start: #7ab929;
$primary-gradient-end: #006027;
$secondary-gradient-start: #f39200;
$secondary-gradient-end: #e30613;
$white: #ffffff;