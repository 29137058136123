




.footer {
    margin-top: 2rem;
    height: 0.5rem;
    background: -webkit-gradient(linear,left top,right top,from(#ff7900),to(#d52b1e));
    background: linear-gradient(90deg,#ff7900 0,#d52b1e);
}